@import '@popsure/dirty-swan/dist/colors';
@import '@popsure/dirty-swan/dist/font-weight';
@import '@popsure/dirty-swan/dist/grid';

.container {
  max-width: 1200px;
  min-height: 440px;
  flex-direction: column;

  @include p-size-desktop {
    flex-direction: row;
    padding: 48px 80px 48px 88px;
    gap: 56px;
    margin: 40px auto 0 auto;
  }
}

.containerWithBackground {
  background-color: $ds-purple-500;
  h1,
  div,
  p {
    color: $ds-white !important;
  }
}

.containerWithoutBackground {
  h1 {
    color: $ds-grey-700;
  }
  div {
    color: $ds-grey-600;
  }
}

.buttonContainer {
  flex-direction: column;
  @include p-size-desktop {
    flex-direction: row;
  }
}

.cta {
  text-decoration: none;
  padding: 12px 0;
  @include p-size-desktop {
    width: 240px;
  }
}

.greyPrimaryCta {
  background-color: $ds-grey-200;
  color: $ds-purple-500;
  transition: 0.3s opacity, 0.3s background-color;

  &:hover {
    background-color: $ds-grey-400;
  }
}

.greySecondaryCta {
  border: 1px solid $ds-grey-200;
  transition: 0.3s border-color;

  &:hover {
    border-color: $ds-primary-700;
  }
}

.purpleSecondaryCta {
  background-color: $ds-purple-50;
}

.image {
  max-width: 100%;

  @include p-size-desktop {
    flex: 1 0 400px;
  }

  img {
    @include p-size-mobile {
      width: 100%;
      height: auto;
      max-width: 100%;
    }
  }

  // Fix blurry image in Webkit-based Browsers like Brave, Chrome & Edge
  image-rendering: -webkit-optimize-contrast;
}
